// import onro from "../../static/onro.png";
// import pickup from "../../static/pickup.png";
// import asankar from "../../static/asankar.png";
// import acistant from "../../static/acistant.png";
// import deliro from "../../static/deliro.svg";
// import doorpost from "../../static/doorpost.png";
// import YONI from "../../static/YONI.png";
// import emrazgo from "../../static/emrazgoLogo.png";
// import hellocourier from "../../static/hellocourier_logo.png";
// import oto from "../../static/oto_logotype.png";
// import vee from "../../static/vee_logotype.png";
// import cartingo from "../../static/cartingo_logotype.png";
// import loco from "../../static/loc_logotype.png";
// import HiO from "../../static/HiOlogotype.png";
// import Fab from "../../static/fablogotype.png";
// import Geenstress from "../../static/geenstress_logotype.png";
// import TEZRAFTAAR from "../../static/TEZRAFTAAR_logotype.png";
// import PickGo from "../../static/PickGoogotype.png";
// import DroppX from "../../static/DroppX_logotype.png";
// import wadelivery from "../../static/wadelivery_logotype.png";
// import Defaultbanner from "./../../static/delivery-5.jpg";
// import Yonibanner from "./../../static/Banner.jpeg";
// import emrazgo_banner from "./../../static/emrazgo_banner.png";
// import hellocourier_banner from "./../../static/hellocourier_banner.png";
// import oto_banner from "../../static/oto_banner.jpg";
// import vee_banner from "../../static/vee_banner.jpg";
// import cartingo_banner from "../../static/cartingo_banner.png";
// import loco_banner from "../../static/loco_banner.png";
// import HiO_banner from "../../static/HiOdelivery5.png";
// import Geenstress_banner from "../../static/geenstressBanner.png";
// import TEZRAFTAAR_banner from "../../static/TEZRAFTAAR_banner.png";
// import PickGo_banner from "../../static/PickGo_banner.jpg";
// import DroppX_banner from "../../static/DroppX_banner.jpg";
// import wadelivery_banner from "../../static/wadelivery_background.png";

import {
  LoginPure,
  RegisterByApple,
  RegisterByFacebook,
  RegisterByGoogle,
  RegisterPure,
} from "../../Redux/actions/actions";
import {
  get_accessToken,
  get_country,
  get_customerPannel_type,
  get_defaultProjectDev,
  get_direction,
  get_Id,
  get_lang,
} from "../UserData/userdate";

import React from "react";
import { Trans } from "react-i18next";
import { repeatReuqestModule } from "./repeatReuqestModule";

import packageJson from "../../../package.json";
import { store } from "../../Redux/store/index";
import { Api_Get_List_AGW } from "../api/ApiGateway";
import { AddNewOrderToList, SetOrderListData } from "../../Redux/actions/actionNames";
import DynamicFont from "react-dynamic-font";

// export const getProjectNameByUrl = (state) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "app.onro.io":
//       return "Onro";
//       break;
//     case "restaurant.catrin-go.com":
//       return "Catringo Restaurant";
//     case "deliver.loco.direct":
//       return "customer portal";
//     case "app.pickup.global":
//       return "Pickup Customer Panel";
//       break;
//     case "app.yoni-app.sn":
//       return "yoni Tableau de bord Customer";
//       break;
//     case "app.deliro.ir":
//       return "دلیرو پنل مشتری";
//       break;
//     case "app.emrazgo.com":
//       return "EMRAZGO Customer Panel";
//       break;
//     case "order.hellocourier.co.ke":
//       return "Customer Panel";
//       break;
//     case "app.otokh.com":
//       return "Customer Panel";

//     case "now.veedelivery.com":
//       return "Customer Panel";

//     case "app.hio.ro":
//       return "Client ";

//     case "dash.droppx.com":
//       return "droppX";

//     case "app.fab.com.np":
//       return "Customer Panel";
//       break;

//     case "app.geenstress.amsterdam":
//       return "Dashboard";
//       break;

//     case "business.trcs.pk":
//       return "TEZRAFTAAR";
//       break;

//     case "deliver.pickngosg.com":
//       return "Delivery • Rides • Buy 4 Me";
//       break;

//       case "my.wadelivery.com":
//         return "Customer App";
//         break;

//     default:
//       return "Onro";
//   }
// };

// export const getProjectName = (state) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "app.onro.io":
//       return "Onro";
//       break;
//     case "restaurant.catrin-go.com":
//       return "Catringo";
//     case "deliver.loco.direct":
//       return "Loco Delivery App";
//     case "app.pickup.global":
//       return "Pickup";
//       break;
//     case "app.yoni-app.sn":
//       return "Yoni";
//       break;
//     case "app.deliro.ir":
//       return "Deliro";
//       break;
//     case "app.emrazgo.com":
//       return "EMRAZGO";
//       break;
//     case "order.hellocourier.co.ke":
//       return "Hello Courier ";
//       break;
//     case "app.otokh.com":
//       return "OTO ";
//       break;
//     case "now.veedelivery.com":
//       return "VEE Delivery Now";
//       break;
//     case "app.hio.ro":
//       return "HiO";

//     case "app.fab.com.np":
//       return "Customer Panel";
//       break;

//     case "app.geenstress.amsterdam":
//       return "Dashboard";
//       break;
//     case "business.trcs.pk":
//       return "TEZRAFTAAR";
//       break;
//     case "deliver.pickngosg.com":
//       return "Delivery • Rides • Buy 4 Me";
//       break;
//     case "dash.droppx.com":
//       return "droppX";

//       case "my.wadelivery.com":
//         return "Customer App";
//         break;

//     default:
//       return "Onro";
//   }
// };
// export const shouldShowPOWER = (state) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "app.yoni-app.sn":
//       return false;
//       break;
//     case "app.emrazgo.com":
//       return false;
//       break;
//     case "app.otokh.com":
//       return false;
//       break;
//     case "now.veedelivery.com":
//       return false;
//       break;
//     case "app.hio.ro":
//       return false;

//     case "deliver.loco.direct":
//       return false;
//       break;
//     case "order.hellocourier.co.ke":
//       return false;
//       break;
//     case "app.fab.com.np":
//       return false;
//     case "dash.droppx.com":
//       return false;

//       case "my.wadelivery.com":
//         return false;

//     default:
//       return true;
//   }
// };

// export const getBaseApiByUrl = () => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     // case "dispatcher.deliro.ir":
//     //   return "https://rest.deliro.ir";
//     //   break;
//     default:
//       return "http://46.105.163.141:3100";
//   }
// };

// export const getBaseSocketByUrl = (state) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "onro.test":
//       return "https://socket.onro.org";
//       break;
//     case "app.onro.org":
//       return "https://socket.onro.org";
//       break;
//     case "restaurant.catrin-go.com":
//       return "https://socket.catrin-go.com";

//     case "deliver.loco.direct":
//       return "https://socket.loco.direct";
//     case "app.onro.io":
//       return "https://socket.onro.io";
//       break;

//     case "app.pickup.global":
//       return "https://socket.pickup.global";
//       break;

//     case "app.deliro.ir":
//       return "https://socket.deliro.ir";
//       break;
//     case "app.yoni-app.sn":
//       return "https://socket.yoni-app.sn";
//       break;
//     case "app.emrazgo.com":
//       return "https://socket.emrazgo.com";
//       break;
//     case "order.hellocourier.co.ke":
//       return "https://socket.hellocourier.co.ke";
//       break;
//     case "app.otokh.com":
//       return "https://socket.otokh.com";
//       break;
//     case "now.veedelivery.com":
//       return "https://socket.veedelivery.com";

//     case "app.hio.ro":
//       return "https://socket.veedelivery.com";

//     case "dash.droppx.com":
//       return "https://socket.droppx.com";

//     case "app.fab.com.np":
//       return "https://socket.fab.com.np";
//       break;
//     case "app.geenstress.amsterdam":
//       return "https://socket.geenstress.amsterdam";
//       break;

//     case "business.trcs.pk":
//       return "https://socket.trcs.pk";
//       break;
//     case "deliver.pickngosg.com":
//       return "https://socket.pickngosg.com";
//       break;

//       case "my.wadelivery.com":
//         return "https://socket.wadelivery.com";
//       break;

//     default:
//       return "https://socket.onro.org";
//   }
// };

// export const getBaseTrackByUrl = (state) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "onro.test":
//       return "https://panel.onro.org";
//       break;
//     case "app.onro.org":
//       return "https://panel.onro.org";
//       break;
//     case "deliver.loco.direct":
//       return "https://portal.loco.direct";
//     case "restaurant.catrin-go.com":
//       return "https://admin.catrin-go.com";
//     case "app.onro.io":
//       return "https://panel.onro.io";
//       break;
//     case "app.pickup.global":
//       return "https://panel.pickup.global";
//     case "app.deliro.ir":
//       return "https://panel.deliro.ir";
//     case "app.yoni-app.sn":
//       return "https://panel.yoni-app.sn";
//     case "app.emrazgo.com":
//       return "https://panel.emrazgo.com";
//     case "order.hellocourier.co.ke":
//       return "https://panel.hellocourier.co.ke";
//     case "app.otokh.com":
//       return "https://panel.otokh.com";
//     case "now.veedelivery.com":
//       return "https://nowadmin.veedelivery.com";

//     case "app.hio.ro":
//       return "https://panel.hio.ro";
//     case "dash.droppx.com":
//       return "https://admin.droppx.com";

//     case "app.fab.com.np":
//       return "https://panel.fab.com.np ";
//       break;

//     case "app.geenstress.amsterdam":
//       return "https://panel.geenstress.amsterdam";
//       break;

//     case "business.trcs.pk":
//       return "https://manager.trcs.pk";
//       break;

//     case "deliver.pickngosg.com":
//       return "https://panel.pickngosg.com";
//       break;

//       case "my.wadelivery.com":
//         return "https://dashboard.wadelivery.com";
//         break;

//     default:
//       // return "https://panel.onro.io";
//       return "https://panel.onro.org";
//   }
// };

// export const getLangByUrl = (state) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "deliver.loco.direct":
//       return "en";

//     case "restaurant.catrin-go.com":
//       return "en";

//     case "onro.test":
//       return "en";
//       break;
//     case "app.onro.org":
//       return "en";
//       break;
//     case "app.onro.io":
//       return "en";
//       break;
//     case "app.pickup.global":
//       return "en";
//       break;
//     case "app.deliro.ir":
//       return "fa";
//       break;
//     case "app.yoni-app.sn":
//       return "fr";
//       break;
//     case "app.emrazgo.com":
//       return "en";
//       break;
//     case "order.hellocourier.co.ke":
//       return "en";
//       break;
//     case "app.otokh.com":
//       return "en";
//       break;
//     case "now.veedelivery.com":
//       return "en";

//     case "app.hio.ro":
//       return "en";

//     case "dash.droppx.com":
//       return "en";
//     case "app.fab.com.np":
//       return "en";

//     case "app.geenstress.amsterdam":
//       return "nl";
//     case "business.trcs.pk":
//       return "en";
//       break;

//     case "deliver.pickngosg.com":
//       return "en";
//       break;

//       case "my.wadelivery.com":
//         return "en";
//         break;

//     default:
//       return "en";
//   }
// };

// export const getFlagByUrl = (state) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "deliver.loco.direct":
//       return "englishflag";
//       break;

//     case "restaurant.catrin-go.com":
//       return "englishflag";

//     case "onro.test":
//       return "englishflag";
//       break;
//     case "app.onro.org":
//       return "englishflag";
//       break;
//     case "app.onro.io":
//       return "englishflag";
//       break;
//     case "app.pickup.global":
//       return "englishflag";
//       break;
//     case "app.deliro.ir":
//       return "persianflag";
//       break;
//     case "app.yoni-app.sn":
//       return "frenchflag";
//       break;
//     case "app.emrazgo.com":
//       return "englishflag";
//     case "order.hellocourier.co.ke":
//       return "englishflag";
//     case "app.otokh.com":
//       return "englishflag";

//     case "now.veedelivery.com":
//       return "englishflag";
//     case "app.hio.ro":
//       return "englishflag";

//     case "dash.droppx.com":
//       return "englishflag";

//     case "app.fab.com.np":
//       return "englishflag";
//     case "app.geenstress.amsterdam":
//       return "dutchflag";

//     case "business.trcs.pk":
//       return "englishflag";
//       break;

//     case "deliver.pickngosg.com":
//       return "englishflag";
//       break;

//       case "my.wadelivery.com":
//         return "englishflag";
//         break;

//     default:
//       return "englishflag";
//   }
// };

// export const getCountryByUrl = (state) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "deliver.loco.direct":
//       return "US";
//       break;
//     case "restaurant.catrin-go.com":
//       return "US";

//     case "onro.test":
//       return "US";
//       break;
//     case "app.onro.org":
//       return "US";
//       break;
//     case "app.onro.io":
//       return "US";
//       break;
//     case "app.pickup.global":
//       return "US";
//       break;
//     case "app.deliro.ir":
//       return "IR";
//       break;
//     case "app.yoni-app.sn":
//       return "SN";
//       break;
//     case "app.emrazgo.com":
//       return "US";
//       break;
//     case "order.hellocourier.co.ke":
//       return "US";
//       break;
//     case "app.otokh.com":
//       return "US";
//       break;

//     case "now.veedelivery.com":
//       return "JO";
//       break;

//     case "dash.droppx.com":
//       return "FI"

//     case "app.hio.ro":
//       return "RO";
//       break;

//     case "app.fab.com.np":
//       return "NP";
//     case "app.geenstress.amsterdam":
//       return "NL";

//     case "business.trcs.pk":
//       return "US";
//     case "deliver.pickngosg.com":
//       return "US";

//       case "my.wadelivery.com":
//         return "US";

//     default:
//       return "US";
//   }
// };

// export const getDirectionByUrl = (state) => {
//   if (["Ltr", "Rtl"].indexOf(get_direction()) > -1) {
//     return get_direction();
//   } else {
//     switch (get_defaultProjectDev() || window.location.hostname) {
//       case "deliver.loco.direct":
//         return "Ltr";
//         break;
//       case "restaurant.catrin-go.com":
//         return "Ltr";
//       case "onro.test":
//         return "Ltr";
//         break;
//       case "app.onro.org":
//         return "Ltr";
//         break;
//       case "app.onro.io":
//         return "Ltr";
//         break;
//       case "app.pickup.global":
//         return "Ltr";
//         break;
//       case "app.deliro.ir":
//         return "Rtl";
//       case "app.yoni-app.sn":
//         return "Ltr";
//       case "app.emrazgo.com":
//         return "Ltr";
//       case "order.hellocourier.co.ke":
//         return "Ltr";
//       case "app.otokh.com":
//         return "Ltr";
//       case "now.veedelivery.com":
//         return "Ltr";

//       case "app.hio.ro":
//         return "Ltr";
//       case "dash.droppx.com":
//         return "Ltr";
//       case "app.fab.com.np":
//         return "Ltr";
//       case "app.geenstress.amsterdam":
//         return "Ltr";

//       case "business.trcs.pk":
//         return "Ltr";
//       case "deliver.pickngosg.com":
//         return "Ltr";

//         case "my.wadelivery.com":
//           return "Ltr";

//       default:
//         return "Ltr";
//     }
//   }
// };

export const isRtl = (state) => {
  if (get_direction() == "Rtl") {
    return true;
  } else {
    return false;
  }
};

// export const getAllLanguages = (state) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "app.onro.io":
//       return [
//         {
//           lang: "en",
//           direction: "Ltr",
//           i18Lang: "English",
//           cssFlag: "englishflag",
//           country: "US",
//         },
//       ];
//       break;

//     case "deliver.pickngosg.com":
//       return [
//         {
//           lang: "en",
//           direction: "Ltr",
//           i18Lang: "English",
//           cssFlag: "englishflag",
//           country: "US",
//         },
//       ];

//       case "my.wadelivery.com":
//         return [
//           {
//             lang: "en",
//             direction: "Ltr",
//             i18Lang: "English",
//             cssFlag: "englishflag",
//             country: "US",
//           },
//         ];
//     case "dash.droppx.com":
//       return [ /*{
//             lang: "fi",
//             direction: "Ltr",
//             i18Lang: "Finnish",
//             cssFlag: "Finnishflag",
//             country: "FI",
//           },*/

//         {
//           lang: "en",
//           direction: "Ltr",
//           i18Lang: "English",
//           cssFlag: "englishflag",
//           country: "US",
//         },
//       ];
//       break;

//     case "app.geenstress.amsterdam":
//       return [{
//         lang: "nl",
//         direction: "Ltr",
//         i18Lang: "Dutch",
//         cssFlag: "dutchflag",
//         country: "NL",
//       },

//       {
//         lang: "en",
//         direction: "Ltr",
//         i18Lang: "English",
//         cssFlag: "englishflag",
//         country: "US",
//       },
//       ];
//       break;

//     case "app.pickup.global":
//       return [
//         {
//           lang: "en",
//           direction: "Ltr",
//           i18Lang: "English",
//           cssFlag: "englishflag",
//           country: "US",
//         },
//       ];
//       break;
//     case "app.yoni-app.sn":
//       return [
//         {
//           lang: "fr",
//           direction: "Ltr",
//           i18Lang: "French",
//           cssFlag: "frenchflag",
//           country: "SN",
//         },
//       ];
//     case "app.emrazgo.com":
//       return [
//         {
//           lang: "en",
//           direction: "Ltr",
//           i18Lang: "English",
//           cssFlag: "englishflag",
//           country: "US",
//         },
//       ];

//     case "order.hellocourier.co.ke":
//       return [
//         {
//           lang: "en",
//           direction: "Ltr",
//           i18Lang: "English",
//           cssFlag: "englishflag",
//           country: "US",
//         },
//       ];

//     case "app.otokh.com":
//       return [
//         {
//           lang: "en",
//           direction: "Ltr",
//           i18Lang: "English",
//           cssFlag: "englishflag",
//           country: "US",
//         },
//       ];

//     case "now.veedelivery.com":
//       return [
//         {
//           lang: "en",
//           direction: "Ltr",
//           i18Lang: "English",
//           cssFlag: "englishflag",
//           country: "US",
//         },
//         {
//           lang: "ar",
//           direction: "Rtl",
//           i18Lang: "Arabic",
//           cssFlag: "arabicflag",
//           country: "JO",
//         },
//       ];

//     case "restaurant.catrin-go.com":
//       return [
//         {
//           lang: "en",
//           direction: "Ltr",
//           i18Lang: "English",
//           cssFlag: "englishflag",
//           country: "US",
//         },
//       ];

//     case "deliver.loco.direct":
//       return [
//         {
//           lang: "en",
//           direction: "Ltr",
//           i18Lang: "English",
//           cssFlag: "englishflag",
//           country: "US",
//         },
//       ];

//     case "app.fab.com.np":
//       return [
//         {
//           lang: "en",
//           direction: "Ltr",
//           i18Lang: "English",
//           cssFlag: "englishflag",
//           country: "US",
//         },
//       ];

//     case "app.hio.ro":
//       return [
//         {
//           lang: "en",
//           direction: "Ltr",
//           i18Lang: "English",
//           cssFlag: "englishflag",
//           country: "US",
//         },
//         {
//           lang: "ro",
//           direction: "Ltr",
//           i18Lang: "Romanian",
//           cssFlag: "romanianflag",
//           country: "RO",
//         },
//       ];

//     case "business.trcs.pk":
//       return [
//         {
//           lang: "en",
//           direction: "Ltr",
//           i18Lang: "English",
//           cssFlag: "englishflag",
//           country: "US",
//         },
//       ];

//     default:
//       return [
//         {
//           lang: "en",
//           direction: "Ltr",
//           i18Lang: "English",
//           cssFlag: "englishflag",
//           country: "US",
//         },
//         {
//           lang: "fa",
//           direction: "Rtl",
//           i18Lang: "Persian",
//           cssFlag: "persianflag",
//           country: "IR",
//         },
//         {
//           lang: "fr",
//           direction: "Ltr",
//           i18Lang: "French",
//           cssFlag: "frenchflag",
//           country: "SN",
//         },
//       ];
//   }
// };

export const CheckNewVersion = () => {
  fetch("/meta.json")
    .then((response) => response.json())
    .then((meta) => {
      const latestVersion = meta.version;
      const currentVersion = global.appVersion;

      const shouldForceRefresh = semverGreaterThan(
        latestVersion,
        currentVersion
      );
      if (shouldForceRefresh) {
        console.log(
          `We have a new version - ${latestVersion}. Should force refresh`
        );
        console.log("Clearing cache and hard reloading...");
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
          });
        }

        // delete browser cache and hard reload
        window.location.reload(true);
      } else {
        console.log(
          `You already have the latest version - ${latestVersion}. No cache refresh needed.`
        );
      }
    });
};

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

export const i18Constructor = (dispatch, i18n, language, seti18Init) => {
  console.log("i18Constructor");
  if (get_lang()) {
    if (language != get_lang()) {
      dispatch({
        type: "changeDirection",
        direction: get_direction(),
        language: get_lang(),
      });
      i18n.changeLanguage(get_lang());
    }
  }

  seti18Init(true);
};

export const RegisterGateWay = (dispatch, data, call) => {
  RegisterApiGateWay(dispatch, data, call);
};

export const RegisterApiGateWay = (dispatch, data, call) => {
  switch (data.thirdPartyLogin) {
    case "appleConnected":
      return dispatch(
        RegisterByApple(
          dispatch,
          {
            idToken: data.appleToken,
            phone: data.phone,
            givenName: data.givenName,
            familyName: data.familyName,
            email: data.email,
            countryCode: "00",
          },
          call
        )
      );

      break;
    case "facebookConnected":
      return dispatch(
        RegisterByFacebook(
          dispatch,
          {
            accessToken: data.facebookToken,
            phone: data.phone,
            givenName: data.givenName,
            familyName: data.familyName,
            email: data.email,
            countryCode: "00",
          },
          call
        )
      );

      break;
    case "googleConnected":
      return dispatch(
        RegisterByGoogle(
          dispatch,
          {
            idToken: data.googleToken,
            phone: data.phone,
            givenName: data.givenName,
            familyName: data.familyName,
            email: data.email,
            countryCode: "00",
          },
          call
        )
      );

      break;
    default:
      return dispatch(
        RegisterPure(
          dispatch,
          {
            header: {
              Captcha: data.Captcha,
              ts: data.ts
            },
            state: {
              phone: data.phone,
              givenName: data.givenName,
              familyName: data.familyName,
              email: data.email,
              countryCode: "00",
            }
          },
          call
        )
      );
  }
};

export const LoginGateWay = (dispatch, data, call) => {
  LoginApiGateWay(dispatch, data, call);
};

export const LoginApiGateWay = (dispatch, data, call) => {
  switch (data.thirdPartyLogin) {
    // case "appleConnected":
    //   return dispatch(RegisterByApple(dispatch , {
    //     idToken: data.appleToken ,
    //     "phone": data.phone,
    //     "givenName": data.givenName,
    //     "familyName": data.familyName,
    //     "email": data.email,
    //     "countryCode": "00"
    //   },call));

    //   break;
    // case "facebookConnected":
    //   return dispatch(RegisterByFacebook(dispatch , {
    //     accessToken: data.facebookToken ,
    //     "phone": data.phone,
    //     "givenName": data.givenName,
    //     "familyName": data.familyName,
    //     "email": data.email,
    //     "countryCode": "00"
    //   },call));

    //   break;
    // case "googleConnected":
    //   return dispatch(RegisterByGoogle(dispatch , {
    //     idToken: data.googleToken ,
    //     "phone": data.phone,
    //     "givenName": data.givenName,
    //     "familyName": data.familyName,
    //     "email": data.email,
    //     "countryCode": "00"
    //   },call));

    //   break;
    default:
      return dispatch(
        LoginPure(
          dispatch,
          {
            header: { Captcha: data.Captcha, ts: data.ts },
            state: { phone: data.phone, }
          },
          call
        )
      );
  }
};

// export const ShowCustomerPanelTitle = (state) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "dash.droppx.com":
//       return false;
//       break;

//     default:
//       return true;
//   }

// }

// export const getLogoTypeByUrl = (state) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "app.onro.io":
//       return onro;
//       break;
//     case "deliver.loco.direct":
//       return loco;
//       break;

//     case "restaurant.catrin-go.com":
//       return cartingo;
//       break;
//     case "app.pickup.global":
//       return pickup;
//       break;
//     case "app.deliro.ir":
//       return deliro;
//       break;
//     case "app.yoni-app.sn":
//       return YONI;
//       break;
//     case "app.emrazgo.com":
//       return emrazgo;
//       break;
//     case "order.hellocourier.co.ke":
//       return hellocourier;
//       break;
//     case "app.otokh.com":
//       return oto;
//       break;
//     case "now.veedelivery.com":
//       return vee;
//       break;

//     case "app.hio.ro":
//       return HiO;
//       break;

//     case "app.fab.com.np":
//       return Fab;
//       break;
//     case "app.geenstress.amsterdam":
//       return Geenstress;
//       break;

//     case "business.trcs.pk":
//       return TEZRAFTAAR;
//       break;

//     case "deliver.pickngosg.com":
//       return PickGo;
//       break;

//     case "dash.droppx.com":
//       return DroppX;
//       break;

//       case "my.wadelivery.com":
//         return wadelivery;
//         break;

//     default:
//       return onro;
//   }
// };

export const CanShowNameFamilyProfile = (state) => {
  if (get_customerPannel_type() == "individual") {
    return true;
  } else {
    return false;
  }
};

export const CanShowSetting = (state) => {
  if (get_customerPannel_type() == "individual") {
    return true;
  } else {
    return false;
  }
};

export const GetSocketOptions = (state) => {
  if (get_customerPannel_type() == "individual") {
    return {
      secure: true,
      query: {
        customerId: get_Id(),
        language: get_lang(),
        apiVersion: "v2",
        apiSource: "web",
        appVersionCode: packageJson.version,
        appVersionName: packageJson.version,
      },
      reconnection: true,
      reconnect_attempt: 99999,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 4000,
      autoConnect: true,
      transports: ["websocket"],
    };
  } else {
    return {
      secure: true,
      query: {
        customerId: get_Id(),
        language: get_lang(),
        apiVersion: "v1",
        apiSource: "web",
        appVersionCode: packageJson.version,
        appVersionName: packageJson.version,
      },
      reconnection: true,
      reconnect_attempt: 99999,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 4000,
      autoConnect: true,
      transports: ["websocket"],
    };
  }
};

export const PickupDone = (state) => {
  switch (state) {
    case "PickedUp":
      return true;
      break;
    case "Done":
      return true;
      break;
    default:
      return false;
  }
};

export const isOrderInDone = (state) => {
  switch (state) {
    case "Done":
      return true;
      break;
    case "done":
      return true;
      break;
    case "Delivered":
      return true;
      break;
    default:
      return false;
  }
};

export const isOrderInCancel = (state) => {
  switch (state) {
    case "CustomerCanceled":
      return true;
      break;
    case "DriverCanceled":
      return true;
      break;
    case "SupportCanceled":
      return true;
      break;

    default:
      return false;
  }
};

export const isOrderInProgress = (state) => {
  switch (state) {
    case "Pending":
      return true;
      break;
    case "Assigned":
      return true;
      break;
    case "PickedUp":
      return true;
      break;
    case "Started":
      return true;
      break;
    case "Arrived":
      return true;
      break;
    case "Collection":
      return true;
      break;
    case "Unassigned":
      return true;
      break;
      case "Draft":
        return true;
        break;
        case "Confirmed":
          return true;
          break;
    default:
      return false;
  }
};

export const repeatReuqest = repeatReuqestModule;

export const CanTrackDriver = (state) => {
  switch (state) {
    case "PickedUp":
      return true;
      break;
    case "Started":
      return true;
      break;
    case "Arrived":
      return true;
      break;
    default:
      return false;
  }
};

// export const AppleLoginCustomerId = (state) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "app.onro.io":
//       return "6N993LX47C";
//     case "app.pickup.global":
//       return "global.pickup.customer";
//     case "app.deliro.ir":
//       return "6N993LX47C";
//     case "app.yoni-app.sn":
//       return "6N993LX47C";
//     case "app.emrazgo.com":
//       return "CWSKAB8XQ4";
//     case "order.hellocourier.co.ke":
//       return "GB7R35FBDP";
//     case "app.otokh.com":
//       return "5JR437GB5K";
//     case "now.veedelivery.com":
//       return "98UM2SD42G";
//     case "restaurant.catrin-go.com":
//       return "6N993LX47C";
//     case "deliver.loco.direct":
//       return "G4952Y49M3";
//     case "app.hio.ro":
//       return "8C74K5NHWM";
//     case "dash.droppx.com":
//       return "M9LC79C223"
//     case "app.fab.com.np":
//       return "G4952Y49M3";
//     case "deliver.pickngosg.com":
//       return null
//     case "my.wadelivery.com":
//       return null

//     default:
//       return "6N993LX47C";
//   }
// };

// export const AppleLoginredirectURI = (state) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "app.onro.io":
//       return "https://app.onro.io";
//     case "app.pickup.global":
//       return "https://app.pickup.global/";
//     case "app.deliro.ir":
//       return "https://app.deliro.ir";
//     case "app.yoni-app.sn":
//       return "https://app.yoni-app.sn";
//     case "app.emrazgo.com":
//       return "https://app.emrazgo.com";
//     case "order.hellocourier.co.ke":
//       return "https://app.hellocourier.co.ke";
//     case "app.otokh.com":
//       return "https://app.otokh.com";
//     case "now.veedelivery.com":
//       return "https://now.veedelivery.com";
//     case "restaurant.catrin-go.com":
//       return "https://restaurant.catrin-go.com";
//     case "deliver.loco.direct":
//       return "https://deliver.loco.direct";
//     case "app.hio.ro":
//       return "https://app.hio.ro";
//     case "dash.droppx.com":
//       return "https://dash.droppx.com";

//     case "app.fab.com.np":
//       return "https://app.fab.com.np";
//       case "my.wadelivery.com":
//         return null

//     default:
//       return "https://app.onro.io";
//   }
// };

// export const FacebookLoginappId = (state) => {
//   //OnroIR  id="1194837280918142"
//   //Onro  id="666630160807861"
//   //Pickup  id="3038885649533203"
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "app.onro.io":
//       return "666630160807861";

//     case "app.pickup.global":
//       return "3038885649533203";

//     case "app.deliro.ir":
//       return "1194837280918142";

//     case "app.yoni-app.sn":
//       return "490664338886171";

//     case "app.emrazgo.com":
//       return "";

//     case "order.hellocourier.co.ke":
//       return "291484626052759";

//     case "app.otokh.com":
//       return "493437251955359";

//     case "now.veedelivery.com":
//       return "2952150521767160";

//     case "restaurant.catrin-go.com":
//       return "299979168545056";
//     case "deliver.loco.direct":
//       return "1947502115406579";
//     case "app.hio.ro":
//       return "4678766815518931";

//     case "app.fab.com.np":
//       return "1947502115406579";

//     case "dash.droppx.com":
//       return "465944145123897"

//     case "deliver.pickngosg.com":
//       return "587040889263267"
//       case "my.wadelivery.com":
//         return null
//     default:
//       return "666630160807861";
//   }
// };

// export const GoogleLogincustomerId = (state) => {
//   //pickup   customerId="149556072176-dtvqa0t2ruacr6frraaesg3u5984n82n.apps.googleusercontent.com"
//   //onro    customerId="254715138014-f09tqcnr4ks6ovs00i6rkss4bdko2qcd.apps.googleusercontent.com"
//   //Deliro   customerId="51088687197-n8okfrg0r9ulb9m4hu8e2tlu4l8sbrt8.apps.googleusercontent.com"
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "app.onro.io":
//       return "49663940387-k08t0lc82equ88n64epp9klqspeatc27.apps.googleusercontent.com";
//     case "app.pickup.global":
//       return "788214719383-jkhcnr57i6flkte8u1f8a56umc9v6jv8.apps.googleusercontent.com";
//     case "app.deliro.ir":
//       return "51088687197-bv0alhejledkq9lb6qq4n0r9hqnj523o.apps.googleusercontent.com";
//     case "app.yoni-app.sn":
//       return "782235285987-g912ee36dvlq9prrpugmbgn0eubammhs.apps.googleusercontent.com";
//     case "app.emrazgo.com":
//       return "208573143740-kcg0p71t0hgnj03absv0m0ggqjjf7uea.apps.googleusercontent.com";
//     case "app.otokh.com":
//       return "356487147167-690neruhlnijtc4005c93nk3955u6in5.apps.googleusercontent.com";
//     case "order.hellocourier.co.ke":
//       return "611337293224-mk5js5i58r7bsibjqr4ri7m3ban92u6l.apps.googleusercontent.com";
//     case "now.veedelivery.com":
//       return "577874740908-uk2jhdirkfrg0hem785s4rgmb2c082dt.apps.googleusercontent.com";
//     case "restaurant.catrin-go.com":
//       return "297915523265-eidtdh15t7epdo1o1jq1nnahtshomlpt.apps.googleusercontent.com";
//     case "deliver.loco.direct":
//       return "753117356992-40661os0v91645i8302e38vvteabdept.apps.googleusercontent.com";
//     case "app.hio.ro":
//       return "710578742791-clvd4bifo6k0guns07gjvvkgbr1ml08o.apps.googleusercontent.com";
//     case "app.fab.com.np":
//       return "772468790690-dcoubhjpmror0jeadm353dlji1pc7l73.apps.googleusercontent.com";
//     case "deliver.pickngosg.com":
//       return "362688129311-rsut2e8er0cfnielbrrp275brem5c453.apps.googleusercontent.com"
//     case "dash.droppx.com":
//       return "216778461929-cjadav1u06ta7nekrrsi31a27sk8qiso.apps.googleusercontent.com"
//     case "my.wadelivery.com":
//         return null

//       default:
//       return "49663940387-k08t0lc82equ88n64epp9klqspeatc27.apps.googleusercontent.com";
//   }
// };

// export const getCountryWithDomain = (state) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "onro.test":
//       return "US";
//     case "app.onro.org":
//       return "US";
//     case "restaurant.catrin-go.com":
//       return "US";
//     case "deliver.loco.direct":
//       return "US";
//     case "app.onro.io":
//       return "US";
//     case "app.pickup.global":
//       return "US";
//     case "app.deliro.ir":
//       return "IR";
//     case "app.yoni-app.sn":
//       return "SN";
//     case "app.emrazgo.com":
//       return "US";
//     case "order.hellocourier.co.ke":
//       return "KE";
//     case "app.otokh.com":
//       return "US";
//     case "now.veedelivery.com":
//       return "JO";
//     case "app.hio.ro":
//       return "RO";
//     case "app.fab.com.np":
//       return "NP";
//     case "app.geenstress.amsterdam":
//       return "NL";
//       break;

//     case "business.trcs.pk":
//       return "PK";
//       break;
//     case "deliver.pickngosg.com":
//       return "SG"
//     case "dash.droppx.com":
//       return "FI"
//       case "my.wadelivery.com":
//         return "IN"

//     default:
//       return get_country();
//   }
// };

export const LoginTextWithDomain = (trans) => {
  switch (get_defaultProjectDev() || window.location.hostname) {
    case "app.onro.io":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {trans("Last mile")}
          </span>
          <span
            className="DeliverySoftware"
            style={{ color: global.config.LoginTextColor }}
          >
            {trans("Delivery Software")}
          </span>
        </p>
      );
    case "app.pickup.global":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {trans("Last mile")}
          </span>
          <span
            className="DeliverySoftware"
            style={{ color: global.config.LoginTextColor }}
          >
            {trans("Delivery Software")}
          </span>
        </p>
      );
    case "app.deliro.ir":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {trans("Last mile")}
          </span>
          <span
            className="DeliverySoftware"
            style={{ color: global.config.LoginTextColor }}
          >
            {trans("Delivery Software")}
          </span>
        </p>
      );
    case "app.yoni-app.sn":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Livrez avec Yoni App
          </span>
          <span
            className="DeliverySoftware"
            style={{ color: global.config.LoginTextColor }}
          ></span>
        </p>
      );
    case "order.hellocourier.co.ke":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Safe and Fast Deliveries
            <br />
            Anyday, Anytime, Anywhere!
          </span>
        </p>
      );
    case "business.trcs.pk":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            TEZRAFTAAR “Faster than you can imagine”
          </span>
        </p>
      );

    case "my.wadelivery.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            wa! Delivery
          </span>
        </p>
      );

    case "app.emrazgo.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Your Delivery Team
          </span>
        </p>
      );
    case "app.otokh.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            OTO Express Delivery, <br />
            Send Anything To Anyone!
          </span>
        </p>
      );
    case "now.veedelivery.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {trans("veeDeliveryLoginText1")}{" "}
          </span>
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {trans("veeDeliveryLoginText2")}
          </span>
        </p>
      );

    case "restaurant.catrin-go.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Catering Delivery Service
          </span>
          {/* <span className="DeliverySoftware" style={{color:global.config.LoginTextColor}}>{trans("Delivery Software")}</span> */}
        </p>
      );

    case "deliver.loco.direct":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Last Mile Delivery Service
          </span>
          {/* <span className="DeliverySoftware" style={{color:global.config.LoginTextColor}}>{trans("Delivery Software")}</span> */}
        </p>
      );

    case "app.hio.ro":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Yes! We deliver.{" "}
          </span>
        </p>
      );

    case "app.fab.com.np":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Keep calm and let Bud do your kaam.
          </span>
          <span
            className="DeliverySoftware DeliverySoftwarefab"
            style={{ color: global.config.LoginTextColor }}
          >
            Pickup/drop and Buy from any stores
          </span>
        </p>
      );

    case "app.geenstress.amsterdam":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Gruwelijke Pakketbezorging
          </span>
        </p>
      );

    case "deliver.pickngosg.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Because we all love convenience
          </span>
        </p>
      );

    case "deliver.pickngosg.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Because we all love convenience
          </span>
        </p>
      );

    case "delivery.gomunchi.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Moving what
          </span>
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            matters the most.
          </span>
        </p>
      );

    case "app.quicksend.ug":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ fontWeight: "400", color: global.config.LoginTextColor }}
          >
            Electrifying Uganda's last-mile delivery for businesses{" "}
          </span>
          <span
            className="lastmile"
            style={{
              fontSize: "20px",
              fontWeight: "300",
              color: global.config.LoginTextColor,
            }}
          >
            {" "}
            Fast, Secure and Reliable Cost Effective Deliveries!{" "}
          </span>
        </p>
      );

    case "app.beswifty.co.uk":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            On-Demand Delivery Service
          </span>
        </p>
      );

    case "app.vroomvroom.ph":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            High Quality Delivery Service
          </span>
        </p>
      );

    case "members.askeriglobal.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          ></span>
        </p>
      );

    case "app.curier-local.ro":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            On demand Delivery
          </span>
        </p>
      );

    case "dashboard.swiftee.co.uk":
      return null;
    // return (
    //   <p className="lastmiledelivery">
    //     <span className="lastmile" style={{color:global.config.LoginTextColor}}>London’s Fastest Courier</span>
    //   </p>
    // );

    case "app.saniclogistics.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Safe & Reliable Logistics Solutions!
          </span>
        </p>
      );

    case "app.dmoapp.in":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Pick & Drop App
          </span>
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            For Everything
          </span>
        </p>
      );

    case "customer.dlxapp.online":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            COURIER - CARGO - COD & CAB SERVICES.
          </span>
        </p>
      );

    case "app.strikeapp.link":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            À portée de clic!
          </span>
        </p>
      );

    case "app.asankar.co":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          ></span>
        </p>
      );

    case "app.sklogistics.do":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            Tu aliado logístico{" "}
          </span>
        </p>
      );

    case "app.anna-now.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            Quick Commerce Solution{" "}
          </span>
        </p>
      );

    case "app.stage.anna-now.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            Quick Commerce Solution{" "}
          </span>
        </p>
      );

    case "app.weedeliver.live":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            Wee Deliver Delivery and Courier Service{" "}
          </span>
        </p>
      );

    case "app.kwikdash.co":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            We Deliver TODAY, TONIGHT, TOMORROW{" "}
          </span>
        </p>
      );

    case "app.rybit-cityspider.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            Rybit{" "}
          </span>
        </p>
      );
    case "app.j-run.az":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            Easy Use and Smile by J-RUN delivery last mile
          </span>
        </p>
      );

    case "app.sprint.xyz":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            Delivering Smiles -
          </span>
        </p>
      );

    case "app.flech.io":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            myflech
          </span>
        </p>
      );

    case "app.aofferonline.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            AOffer Delivery Service
          </span>
        </p>
      );

    case "app.aladden.org":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Car recovery{" "}
          </span>
        </p>
      );

    case "app.3latreqi.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            3latreqi Delivery Service{" "}
          </span>
        </p>
      );

    case "app.getitpicked.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            On-Demand Delivery App{" "}
          </span>
        </p>
      );

    case "portal.floti.io":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            AI Powered Logistics{" "}
          </span>
        </p>
      );

    case "webapp.famvalet.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            Your Maintenance Valet
          </span>
        </p>
      );

    case "account.swiftitexpress.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            Courier / Delivery Service
          </span>
        </p>
      );

    case "app.kerryexpress.ph":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            Kerry Express Delivery Portal
          </span>
        </p>
      );

    case "app.automoto.ph":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            Kerry Express Delivery Portal
          </span>
        </p>
      );

    case "webapp.flyto.fi":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            Tilaus- ja seurantajärjestelmä{" "}
          </span>
        </p>
      );

    case "dashboard.tryrappid.com":
      return null;

    case "book.bristolcountycouriers.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            Delivery just got easier.{" "}
          </span>
        </p>
      );

    case "app.clarabiofuels.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {" "}
            Turning Waste into Energy{" "}
          </span>
        </p>
      );

    case "app.scooty.gr":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Delivery Expert{" "}
          </span>
        </p>
      );

    case "dashboard.nobodi.com":
      return null

    case "customer.gripx.ph":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Delivering excellence, on time, every time{" "}
          </span>
        </p>
      );


    case "app.bigtic-it.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            YOUR HOME, OUR HANDS
          </span>
        </p>
      );

    case "app.24couriers.co.uk":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Same Day Couriers
          </span>
        </p>
      );

    case "app.jenatrucks.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Secure and Cost Effective Delivery Services
          </span>
        </p>
      );

    case "app.geshikt.be":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Delivering quickly and with care
          </span>
        </p>
      );

    case "app.skiptheq.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            SkiptheQ <br />
            We Come To You

          </span>
        </p>
      );

    case "app.parcelbeep.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Parcel Beep Delivery Software
          </span>
        </p>
      );


    case "app.maydaycourier.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Mayday Courier Delivery
          </span>
        </p>
      );

    case "app.pink.express":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor ,textAlign:"left"}}
          >
             SMART.<br />
             FLINK.<br />
             PiNK.
          </span>
        </p>
      );

    case "app.reflexcourier.ca":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Reliable delivery
          </span>
        </p>
      );
    case "app.snapboxjo.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Snapbox Delivery
          </span>
        </p>
      );

    case "app.anypoint.live":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            AnyPoint
          </span>
        </p>
      );

    case "app.thetruckingforme.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Thetruckingforme Delivery Solutions
          </span>
        </p>
      );

    case "app.privitrides.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            Privit Rides & Delivery
          </span>
        </p>
      );

    case "webapp.beyond-delivery.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            NEEDED SOLUTIONS
          </span>
        </p>
      );


    case "client.mannaev.com":
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            FAST DELIVERY, ANYTIME, ANYWHERE
          </span>
          <span
            className="DeliverySoftware"
            style={{ color: global.config.LoginTextColor ,fontSize:"25px" ,marginTop:"20px" }}
          >
            Deliver whatever you want, 24/7 to all destinations, with our same-day on-demand delivery service.
          </span>

        </p>
      );


      case "app.joradelivery.com":
        return (
          <p className="lastmiledelivery">
            <span
              className="lastmile"
              style={{ color: global.config.LoginTextColor }}
            >
              Metrolina Business Express
            </span>
          </p>
        );

        case "app.couriersexpress.com":
            return (
              <p className="lastmiledelivery">
                <span
                  className="lastmile"
                  style={{ fontWeight: "400", color: global.config.LoginTextColor }}
                >
                  Welcome Back!

                </span>
                <span
                  className="lastmile"
                  style={{
                    fontSize: "20px",
                    fontWeight: "300",
                    color: global.config.LoginTextColor,
                  }}
                >
                 to a world of possibilities…
                </span>
              </p>
            );


            case "dashboard.swishrcourier.com":
              return (
                <p className="lastmiledelivery">
                  <span
                    className="lastmile"
                    style={{ color: global.config.LoginTextColor }}
                  >
                    Swishr Revolutionising 
                  </span>
                  <span
                    className="lastmile"
                    style={{ color: global.config.LoginTextColor }}
                  >
                    Same-Day Delivery
                  </span>
                </p>
              );

              case "app.ondelivery.us":
                return (
                  <p className="lastmiledelivery">
                    <span
                      className="lastmile"
                      style={{ color: global.config.LoginTextColor }}
                    >
                      Your World
                    </span>
                    <span
                      className="lastmile"
                      style={{ color: global.config.LoginTextColor }}
                    >
                      Delivered!
                    </span>
                  </p>
                );
                case "app.loadmanexpress.com":
                  return (
                    <p className="lastmiledelivery">
                      <span
                        className="lastmile"
                        style={{ color: global.config.LoginTextColor }}
                      >
                       Onro -Loadman Express India Delivery
                      </span>
                    </p>
                  );

                  case "app.eaglepost.com":
                    return (
                      <p className="lastmiledelivery">
                        <span
                          className="lastmile"
                          style={{ color: global.config.LoginTextColor }}
                        >
                         Eagle Post
                        </span>
                      </p>
                    );
                    case "app.1000solutions.ae":
                      return (
                        <p className="lastmiledelivery">
                          <span
                            className="lastmile"
                            style={{ color: global.config.LoginTextColor }}
                          >
                           Client Panel
                          </span>
                        </p>
                      );
                      case "app.pickndrop.io":
                        return (
                          <p className="lastmiledelivery">
                            <span
                              className="lastmile"
                              style={{ color: global.config.LoginTextColor }}
                            >
                             Your Parcels, Our Priority
                            </span>
                          </p>
                        );
                        case "app.ezdeliveryservices.com":
                          return (
                            <p className="lastmiledelivery">
                              <span
                                className="lastmile"
                                style={{ color: global.config.LoginTextColor }}
                              >
                               Send an order with us, it’s EZ
                              </span>
                            </p>
                          );
                          case "app.deliveryondemand.co":
                            return (
                              <p className="lastmiledelivery">
                                <span
                                  className="lastmile"
                                  style={{ color: global.config.LoginTextColor }}
                                >
                                 Same-day & On-Demand Pick-up and Delivery Service
                                </span>
                              </p>
                            );
                            case "app.blinkds.com":
                              return (
                                <p className="lastmiledelivery">
                                  <span
                                    className="lastmile"
                                    style={{ color: global.config.LoginTextColor }}
                                  >
                                   Blink Delivery Software
                                  </span>
                                </p>
                              );
                              case "app.odos.tech":
                                return (
                                  <p className="lastmiledelivery">
                                    <span
                                      className="lastmile"
                                      style={{ color: global.config.LoginTextColor }}
                                    >
                                     ODOS Last Mile
                                    </span>
                                  </p>
                                );
                                case "app.jpcarrier.ca":
                                  return (
                                    <p className="lastmiledelivery">
                                      <span
                                        className="lastmile"
                                        style={{ color: global.config.LoginTextColor }}
                                      >
                                       Unlock ways to reduce cost and boost efficiency for your business
                                      </span>
                                    </p>
                                  );
                                  case "app.aurelapp.com":
                                    return (
                                      <p className="lastmiledelivery">
                                        <span
                                          className="lastmile"
                                          style={{ color: global.config.LoginTextColor }}
                                        >
                                         Ofrece a tus clientes entregas al instante, al día siguiente y a nivel nacional.
                                        </span>
                                      </p>
                                    );

                                    case "app.ryderx.at":
                                      return (
                                        <p className="lastmiledelivery">
                                          <span
                                            className="lastmile"
                                            style={{ color: global.config.LoginTextColor }}
                                          >
                                           RyderX Delivers
                                          </span>
                                        </p>
                                      );
                case "app.kwikr.com":
                  return null

      case "app.landeber.com":
        return null
  
    default:
      return (
        <p className="lastmiledelivery">
          <span
            className="lastmile"
            style={{ color: global.config.LoginTextColor }}
          >
            {trans("Last mile")} {trans("Delivery Software")}
          </span>
          {/* <span className="DeliverySoftware" style={{color:global.config.LoginTextColor}}>{trans("Delivery Software")}</span> */}
        </p>
      );
  }
};

// export const LoginBannerWithDomain = (trans) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "app.yoni-app.sn":
//       return Yonibanner;
//     case "app.emrazgo.com":
//       return emrazgo_banner;
//     case "order.hellocourier.co.ke":
//       return hellocourier_banner;
//     case "app.otokh.com":
//       return oto_banner;
//     case "now.veedelivery.com":
//       return vee_banner;
//       break;
//     case "restaurant.catrin-go.com":
//       return cartingo_banner;
//     case "deliver.loco.direct":
//       return loco_banner;
//     case "app.hio.ro":
//       return HiO_banner;
//     case "app.fab.com.np":
//       return Defaultbanner;
//     case "app.geenstress.amsterdam":
//       return Geenstress_banner;
//     case "business.trcs.pk":
//       return TEZRAFTAAR_banner;
//     case "deliver.pickngosg.com":
//       return PickGo_banner;
//     case "dash.droppx.com":
//       return DroppX_banner
//       case "my.wadelivery.com":
//           return wadelivery_banner
//     default:
//       return Defaultbanner;
//   }
// };

// export const FavIconWithDomain = (trans) => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "app.pickup.global":
//       return "/pickup.png";
//       break;

//     case "restaurant.catrin-go.com":
//       return "/cartingo.png";
//       break;
//     case "deliver.loco.direct":
//       return "/loco.png";
//       break;

//     case "app.onro.io":
//       return "/onro.png";
//       break;
//     case "app.onro.org":
//       return "/onro.png";
//       break;
//     case "app.acistant.xyz":
//       return "/acistant.png";
//       break;
//     case "app.asankar.co":
//       return "/asankar.png";
//       break;
//     case "app.deliro.ir":
//       return "/deliro.png";
//       break;
//     case "app.doorpost.com.au":
//       return "/doorpost.png";
//       break;
//     case "app.yoni-app.sn":
//       return "/yoni.png";
//       break;
//     case "app.emrazgo.com":
//       return "/emrazgo.png";
//       break;
//     case "order.hellocourier.co.ke":
//       return "/hellocourier.png";
//     case "app.otokh.com":
//       return "/oto.png";
//       break;
//     case "now.veedelivery.com":
//       return "/vee.png";
//       break;
//     case "app.hio.ro":
//       return "/HiOfav_icon.png";
//       break;
//     case "app.fab.com.np":
//       return "/fab.png";
//     case "app.geenstress.amsterdam":
//       return "/geenstress.png";
//     case "business.trcs.pk":
//       return "/TEZRAFTAAR.png";
//     case "deliver.pickngosg.com":
//       return "/PickGo.png";
//     case "dash.droppx.com":
//       return "/DroppX.png"
//     case "my.wadelivery.com":
//       return "/wadelivery.png"

//     default:
//       return "/onro.png";
//   }
// };

export const faviconUpdate = (trans) => {
  try {
    const favicon = document.getElementById("favicon");
    favicon.href = global.config.FavIconWithDomain;
  } catch (error) { }
};

export const CheckSocketOrderState = (state, call) => {
  let ActiveStates = [
    "Pending",
    "Unassigned",
    "Assigned",
    "PickedUp",
    "Started",
    "Arrived",
    "Collection",
    "Transit",
    "Delivering",
    "Unassigned",
  ];
  var array = ActiveStates.filter((arr) => arr.length > 2);
  if (array.length == 0) {
    call(true);
  } else {
    const match = array.find((element) => element == state);
    if (match) {
      call(true);
    } else {
      call(false);
    }
  }
};

// export const ShowThirdPartyLogin = () => {
//   switch (get_defaultProjectDev() || window.location.hostname) {
//     case "app.fab.com.np":
//       return false;
//     case "app.geenstress.amsterdam":
//       return false;
//     case "business.trcs.pk":
//       return false;
//     // case "dash.droppx.com":
//     //   return false;
//     default:
//       return true;
//   }
// };

export const ResetOrderListAfterCreateOrder = () => {
  store.dispatch(
    SetOrderListData({
      reset: true,
    })
  );
  Api_Get_List_AGW(
    store.dispatch,
    {
      page: 0,
    },
    (call) => { }
  );
};


export function getCenterMap(mapRef, center) {
  // try {
  //    return {
  //     lng: mapRef.current.leafletElement.getCenter().lng,
  //     lat: mapRef.current.leafletElement.getCenter().lat,
  //   }

  // } catch (error) {
  //   return {
  //     lng: 0,
  //     lat: 0,
  //   }

  // }

  try {
    if (global.config.applicationMap.type) {
      if (global.config.applicationMap.type == "osm") {
        return {
          lng: mapRef.current.leafletElement.getCenter().lng,
          lat: mapRef.current.leafletElement.getCenter().lat,
        };
      } else {
        if (!mapRef) {
          return center;
        }
        const newCenter = mapRef.getCenter();
        return {
          lng: newCenter.lng(),
          lat: newCenter.lat(),
        };
      }
    } else {
      return center
        ? center
        : {
          lng: 0,
          lat: 0,
        };
    }
  } catch (error) {
    return {
      lng: 0,
      lat: 0,
    };
  }
}

export function setCenterMap(mapRef, e) {
  // console.log("setCenterMap " , mapRef , e)
  // mapRef.current.leafletElement.setView(e, 17);

  try {
    if (global.config.applicationMap.type) {
      if (global.config.applicationMap.type == "osm") {
        mapRef.current.leafletElement.setView(e, 17);
      } else {
        const bounds = new window.google.maps.LatLngBounds();
        var myLatLng = new window.google.maps.LatLng(e.lat, e.lng);
        bounds.extend(myLatLng);

        mapRef.fitBounds(bounds);
        mapRef.setZoom(16);
      }
    }
  } catch (error) { }
}
