export const GreekLabel = {
  "Cutomerpanel": "Πάνελ Πελατών",
  "login": "Σύνδεση",
  "Register": "Εγγραφή",
  "en": "English",
  "fa": "Persian",
  "individual": "Ιδιώτης",
  "Business": "Επιχείρηση",
  "Phonenumber": "Τηλέφωνο",
  "Continue": "Συνέχεια",
  "phonenumberhasnotregisterd": "Ο αριθμός τηλεφώνου δεν είναι καταχωρημένος",
  "Username": "Όνομα Χρήστη",
  "title": "Τίτλος",
  "Password": "Κωδικός",
  "ExOnro": "yourcompanyusername",
  "Exstart": "********",
  "googleConnected": "Σύνδεση με Google",
  "facebookConnected": "Σύνδεση με Facebook",
  "appleConnected": "Σύνδεση με Apple ID",
  "plsenterphonnumber": "Συμπληρώστε τον αριθμό τηλεφώνου σας",
  "firstnnameloginplaceholder": "Π.χ. John",
  "lastnameloginplaceholder": "Π.χ. Doe",
  "Firstname": "Όνομα",
  "Lastname": "Επώνυμο",
  "email": "Email",
  "emailPlaceholder": "Π.χ. john_doe@gmail.com",
  "comingsoon": "Σύντομα",
  comingsoondesc: "Για να εγγραφείτε η επιχείρησή σας, κάντε κλικ εδώ",
  "comingsoondesc2": "Επικοινωνήστε μαζί μας για να δημιουργήσετε έναν επαγγελματικό λογαριασμό",
  contactUs: "επικοινωνήστε μαζί μας",
  "CustomerAlreadyRegistered": "Ο πελάτης είναι ήδη εγγεγραμμένος, παρακαλούμε συνδεθείτε",
  "registerCompleted": "Εισάγετε τον κωδικό επαλήθευσης",
  "googletokenerror": "Πρόβλημα με το Google Token",
  "facebooktokenerror": "Πρόβλημα με το Facebook Token",
  "appletokenerror": "Πρόβλημα με το Apple Token",
  "CustomerNotRegistered": "Ο πελάτης δεν είναι εγγεγραμμένος",
  "fr": "French",
  "de": "Germany",
  "InvalidVerificationCode": "Μη έγκυρος κωδικός επαλήθευσης",
  "a": "Δεν ήταν δυνατή η εύρεση των πληροφοριών σας",
  "welcome": "Καλώς ήρθατε",
  "SmsLimitationError": "Παρακαλώ δοκιμάστε ξανά σε λίγα λεπτά",
  "resendCodeWithTime": "επανάληψη αποστολής κωδικού (xxx)",
  "resendCode": "Επανάληψη αποστολής κωδικού",
  "VerificationcodePlaceHolder": "Π.χ. 1234",
  "Verificationcode": "Κωδικός Επαλήθευσης",
  "VerificationcodeText": "Συμπληρώστε τον κωδικό που στάλθηκε στο xxx",
  "VerificationCodeExpired": "Ο κωδικός επαλήθευσης έχει λήξει",
  "Pending": "Σε εκκρεμότητα",
  "Unassigned": "Χωρίς Ανάθεση",
  "Assigned": "Σε εξέλιξη",
  "PickedUp": "Σε εξέλιξη",
  "Started": "Σε εξέλιξη",
  "statusInProgress": "Σε εξέλιξη",
  "Done": "Ολοκληρώθηκε",
  "CustomerCanceled": "Ακυρώθηκε",
  "DriverCanceled": "Ακυρώθηκε",
  "SupportCanceled": "Ακυρώθηκε",
  "Arrived": "Σε εξέλιξη",
  "new": "Νέα",
  "Historyofyourorderswillbehere": "Το ιστορικό παραγγελιών σας θα εμφανίζεται εδώ",
  "otherdropoffs": "άλλα σημεία παράδοσης",
  "Orderhistory": "Ιστορικό Παραγγελιών",
  "Noorder": "Καμιά παραγγελία",
  "loadingList": "Λαμβάνονται πληροφορίες, παρακαλώ περιμένετε",
  "loadingError": "Παρουσιάστηκε πρόβλημα κατά την ανάκτηση των δεδομένων",
  "retry": "Δοκιμάστε ξανά",
  "Create_your_first_requst": "Δημιουργήστε μια νέα παραγγελία",
  "List_of_you_active_orders_will_be_here": "Η λίστα με τις ενεργές παραγγελίες σας θα είναι εδώ",
  "Aboutus": "Σχετικά με εμάς",
  "Readour": "Διαβάστε",
  "privaypolicy": "πολιτική απορρήτου",
  "Transactions": "Συναλλαγές",
  "NoTransaction": "Δεν έχετε καμία συναλλαγή",
  "Historyofyourransactionswillbehere": "Το ισορικό των συναλλαγών σας θα εμφανίζεται εδώ",
  "Settings": "Ρυθμίσεις",
  "Language": "Γλώσσα",
  "English": "English",
  "Persian": "Persian",
  "French": "French",
  "Logout": "Αποσύνδεση",
  "logotTitle": "Αποσύνδεση από τον λογαριασμό ?",
  "logoutDescription": "Θα αποσυνδεθείτε από τον λογαριασμό και θα πρέπει να ξανά συνδεθείτε αργότερα",
  "confirmLogout": "Ναι, αποσύνδεση",
  "skipLogout": "Ακύρωση",
  "savesettings": "Αποθήκευση ρυθμίσεων",
  "emailInvalid": "Εσφαλμένο email",
  "Neworder": "Νέα παραγγελία",
  "Service": "Υπηρεσία",
  "poperLoading": "Παρακαλώ περιμένετε",
  "Pickup": "Παραλαβή",
  "SetPickup": "Ορισμός Σημείου Παραλαβής",
  "ChooseoOnMap": "Επιλέξτε στον χάρτη",
  "Fornow": "Για τώρα",
  "Scheduled": "Προγραμματισμένο",
  "before": "Πριν :",
  "after": "Μετά :",
  "confirm": "Επιβεβαίωση",
  "Settime": "Ορίστε την ώρα",
  "fo": "Για",
  "Cancel": "Ακύρωση",
  "Pleasetryanotherkeyword": "Δοκιμάστε άλλη λέξη-κλειδί",
  "Noinformationfound": "Δεν βρέθηκαν πληροφορίες",
  "address": "Διεύθυνση",
  "block": "Κτήριο",
  "Floor": "Όροφος",
  "Unit": "Μονάδα",
  "senderfullname": "Όνομα Αποστολέα",
  "senderphonenumber": "Τηλέφωνο Αποστολέα",
  "notesfordriver": "Σημείωση προς Οδηγό",
  "DropOff": "Παράδοση",
  "SetDropOff": "Ορισμός Σημείου Παράδοσης",
  "fetchingdata": "Λαμβάνονται πληροφορίες...",
  "Recieverfullname": "Όνομα Παραλήπτη",
  "Recieverphonenumber": "Τηλέφωνο Παραλήπτη",
  "undefined": "Τύπος υπηρεσίας",
  "PromotionCode": "Κουπόνι",
  "Delete": "Διαγραφή",
  "Save": "Αποθήκευση",
  "PromoCodeValid": "Αποθήκευση Κουπονού",
  "SenderWallet": "Πορτοφόλι",
  "SenderCash": "Μετρητά",
  "ReceiverCash": "Μετρητά (Παραλήπτη)",
  "lowBalance": "Χαμηλό υπόλοιπο",
  "Balance": "Πορτοφόλι",
  "Methods": "Μέθοδοι Πληρωμής",
  "balanceError": "Το υπόλοιπό σας πρέπει να είναι τουλάχιστον ίσο με την τιμή της παραγγελίας",
  "SendOrder": "Αποστολή",
  "feedback": "Feedback",
  "Feedback": "Feedback",
  "Orderagain": "Παράγγειλε ξανά",
  "Createdsuccessfully": "Δημιουργήθηκε επιτυχώς",
  "chooseAmount": "Επιλογή λογαριασμού",
  "orenterthepreferedamount": "Προτιμώμενο ποσό:",
  "enteramount": "Επιθυμητή Τιμή",
  "pay": "Πληρωμή",
  "selectPaymentGateway": "Επιλέξτε την πύλη πληρωμής",
  "Time_MachineText": "Θέλετε να επαναφέρετε τις προηγουμένως μη καταχωρημένες πληροφορίες παραγγελίας σας;",
  "yes": "Ναι",
  "ignore": "Αγνόηση",
  "Order": "Παραγγελία",
  "statusPending": "Σε εκκρεμότητα",
  "statusUnassigned": "Χωρίς Ανάθεση",
  "statusAssigned": "Ανατέθηκε",
  "statusStarted": "Ξεκίνησε",
  "statusPickedUp": "Παραλήφθηκε",
  "statusDone": "Ολοκληρώθηκε",
  "statusCustomerCanceled": "Ο πελάτης ακύρωσε",
  "statusDriverCanceled": "Ο οδηγός ακύρωσε",
  "statusArrived": "Έφτασε",
  "statusSupportCanceled": "Ακυρώθηκε από υποστήριξη",
  "statusCanceled": "Ακυρώθηκε",
  "Phone": "Τηλέφωνο",
  "additonalservice": "επιπλέον υπηρεσία",
  "CallDriver": "Κάλεσε τον οδηγό",
  "Message": "Μήνυμα",
  "orderInfoError": "Παρουσιάστηκε σφάλμα κατά τη λήψη δεδομένων",
  "support": "Υποστήριξη",
  "map": "Χάρτης",
  "receipt": "Απόδειξη",
  "photo": "Photo",
  "ServicePhoto": "Φωτογραφία Υπηρεσίας",
  "Addphoto": "Προσθήκη φωτογραφίας",
  "Estime": "Εκτ. Χρόνος",
  "Espath": "Εκτ. Διαδρομή",
  "pod": "Αποδεικτικό παράδοσης",
  "Notes": "Σημειώσεις",
  "Photo": "Φωτογραφία",
  "Signature": "Υπογραφή",
  "Nonotes": "Καμία σημείωση",
  "Nosignature": "Καμία υπογραφή",
  "Nophoto": "Καμία φωτογραφία",
  "Price": "Τιμή",
  "Receiver": "Από παραλήπτη",
  "Sender": "Από αποστολέα",
  "Cash": "Μετρητά",
  "Canceltravel": "Ακύρωση αιτήματος",
  "CancellText": "Μετά την ακύρωση, η παραγγελία ακυρώνεται για τον οδηγό",
  "Estimated": "Περίπου",
  "Wallet": "Υπόλοιπο",
  "Copylink": "Αντιγραφή συνδέσμου",
  "repeatOrder": "Επανάληψη παραγγελίας",
  "Edit": "Επεξεργασία",
  "confirmCancel": "Ναι",
  "paymentmethod": "Τρόπος πληρωμής",
  "SucceessFul": "ολοκληρώθηκε επιτυχώς",
  "errorinServer": "Πρόβλημα διακομιστή",
  "Comment": "Σχόλιο",
  "Exfeedbackinput": "Π.χ. η παράδοση έγινε πολύ γρήγορα",
  "Wallett": "Πορτοφόλι",
  "TypeHere": "Πληκτρολογήστε εδώ",
  "Loadingmessages": "Φόρτωση μηνυμάτων",
  "PoweredBy": "Powered by",
  "newincomemessage": "Λάβατε νέο μήνυμα",
  "newincomemessageanother": "Έχετε νέο μήνυμα σε άλλη παραγγελία",
  "youhaveunreadmessage": "Μη αναγνωσμένο μήνυμα",
  "Vehicletype": "Τύπος οχήματος",
  "Pickup2": "Παραλαβή",
  "Dropoff2": "Παράδοση",
  "Events": "Γεγονότα",
  "allEvents": "Όλα τα γεγονότα",
  "star": "αστέρι",
  "Yourrate": "Η αξιολόγιση σου",
  "CopyOrderlink": "Αντιγραφή συνδέσμου παραγγελίας",
  "CopyPickuplink": "Ανιγραφή συνδέσμου παραλαβής",
  "CopyDeliverylink": "Αντιγραφή συνδέσμου παράδοσης",
  "Nointernetconnection": "Δεν υπάρχει σύνδεση στο διαδίκτυο",
  "close": "κλείσιμο",
  "seen": "Προβλήθηκε",
  "controlThatbeforeLoweEqualAfter": "Ο χρόνος \"Πριν :\" δεν μπορεί να είναι μικρότερος από \"Μετά:\"",
  "controlThatbeforeAndAfterMustBiggerthanCurrentDate": "Οι χρόνοι \"πριν:\" και \"μετά:\" δεν θα μπορούν να είναι μικρότεροι από την τρέχουσα ώρα",
  "errorInPanelSetting": "Σφάλμα στις ρυθμίσεις κράτησης, επικοινωνήστε με την υποστήριξη",
  "Arabic": "Arabic",
  "ar": "Arabic",
  "veeDeliveryLoginText1": "Αποστολή Οτιδήποτε",
  "veeDeliveryLoginText2": "Σε Οποιονδήποτε, ΤΩΡΑ!",
  "Schedule": "Προγραμματισμός",
  "Date": "Ημερομηνία",
  "Time": "Ώρα",
  "noDateToShow": "Δεν υπάρχει ημερομηνία για προβολή",
  "noTimeToShow": "Δεν υπάρχει ώρα για προβολή",
  "Apply": "Εφαρμογή",
  "Increaseyourbalance": "Αυξήστε το υπόλοιπο σας",
  "AddBalance": "Προσθήκη υπολοίπου",
  "Addressbook": "Αρχείο διευθύνσεων",
  "AddressbookSearchbar": "Αναζήτηση κατά διεύθυνση, τίτλο και κωδικό",
  "Removedefault": "Αναζήτηση κατά διεύθυνση, τίτλο και κωδικό",
  "Setdefaultpickup": "Ορισμός προεπιλεγμένης παραλαβής",
  "Remove": "Αφαίρεση",
  "newAddress": "Νέα Διεύθυνση",
  "NewAddressbookInfoTitle": "Πληροφορίες",
  "Addressdetails": "Λεπτομέρειες Διεύθυνσης",
  "Title": "Τίτλος",
  "Code": "Κώδικας",
  "editAddress": "Επεξεργασία διεύθυνσης",
  "AddressbookDropOf": "Αρχείο διευθύνσεων",
  "AddressbookPickup": "Αρχείο διευθύνσεων",
  "hide": "Κρύψιμο",
  "Nosavedaddress": "Καμία αποθηκευμένη διεύθυνση",
  "NosavedaddressDesc": "Μπορείτε να δημιουργήσετε μια, κάνοντας κλικ στο εικονίδιο σελιδοδείκτη δίπλα στη διεύθυνση ή μεταβείτε στη σελίδα του βιβλίου διευθύνσεων",
  "Romanian": "Romanian",
  "ro": "Romanian",
  "Dutch": "Dutch",
  "Shipmentlabel": "Ετικέκα αποστολής",
  "Name": "Όνομα",
  "CashOnDelivery": "Αντικαταβολή",
  "EnterAmount": "Συμπληρώστε το ποσό",
  "COD": "Αντικαταβολή",
  "Surchargexxx": "Επιπλέον χρέωση xxx",
  "Pricedetails": "Λεπτομέρειες τιμής",
  "minxxx": "xxx λεπτά",
  "toPickup": "για Παραλαβή",
  "toDropOff": "γα Παράδοση",
  "AddCreditCard": "Προσθήκη Τραπεζικής Κάρτας",
  "CustomerWallet": "Πορτοφόλι πελάτη",
  "Add": "Προσθήκη",
  "Addedsuccessfully": "Προστέθηκε επιτυχώς",
  "paymentmethods": "Τρόποι πληρωμής",
  "NoCards": "Καμία κάρτα",
  "Listofyourcardswillbehere": "Η λίστα με τις κάρτες σας θα εμφανίζεται εδώ",
  "CardInfo": "Στοιχεία κάρτας",
  "ExpireDate": "Ημερομηνία λήξης",
  "CVC": "CVC",
  "PostalCode": "Ταχυδρομικός Κώδικας",
  "RemoveCardq": "Αφαίρεση Κάρτας ?",
  "RemoveCardA": "Η κάρτα θα αφαιρεθεί και δεν μπορεί να χρησιμοποιηθεί πλέον",
  "YesRemove": "Ναι, αφαίρεση",
  "Cardisinuse": "Κάρτα σε χρήση",
  "ok": "Ok",
  "Pleasetypeyourwordstosearch": "Πληκτρολογήστε τη λέξη-κλειδί σας για αναζήτηση",
  "spanish": "Spanish",
  "es": "Spanish",
  "Invoices": "Τιμολόγια",
  "noInvoice": "No. xxx",
  "germany": "Germany",
  "nl": "Dutch",
  "downloadClientApplication": "Κατέβασε την εφαρμογή xxx",
  "AssignedAt": "Ανάθεση μέχρι xxx",
  "StartedAt": "Εκκίνηση μέχρι xxx",
  "ArrivedAt": "Φτάνει σε xxx",
  "PickedUpAt": "Παραλαβή στις xxx",
  "DoneAt": "Ολοκληρώση στις xxx",
  "Russian": "Russian",
  "Azerbaijani": "Azerbaijani",
  "rus": "Russian",
  "aze": "Azerbaijani",
  "Support": "Υποστήριξη",
  "SendRequest": "Αποστολή αιτήματος",
  "CallTheSupport": "Κάλεσε Υποστήριξη",
  "Choosethesubject": "Επιλογή θέματος",
  "Seefrequentquestions": "Συχνές Ερωτήσεις",
  "Details": "Λεπτομέρειες",
  "Send": "Αποστολή",
  "MessageBox": "Κουτί Μηνυμάτων",
  "NoMessages": "Κανένα μήνυμα",
  "Yourmessageswillbehere": "Τα μηνύματα σας θα εμφανίζεται εδώ",
  "Portuguese": "Portuguese",
  "pt": "Portuguese",
  "Greek": "Ελληνικά",
  "el": "Ελληνικά",
  "registerBussinessUsername": "Όνομα χρήστη (τουλάχιστον 5 χαρακτήρες)",
  "registerBussinessPassword": "Κωδικός πρόσβασης (τουλάχιστον 6 χαρακτήρες)",
  "ReferenceID": "Αναγνωριστικό αναφοράς",
  "EnterId": "Antaa henkilöllisyystodistus",
  "Orderid": "Order id",
  "EnterCode": "Εισάγετε τον κωδικό",
  "AustrianGerman": "Αυστριακό γερμανικό",
  "deu": "Αυστριακό γερμανικό",
  "pop": "Απόδειξη παραλαβής",
  "Options": "Επιλογές",
  "RequiredText": "Απαιτείται",
  "PleaseSelect": "Παρακαλώ επιλέξτε",
  "Optimizedropoffs":"Βελτιστοποιήστε ταγόνες",
  "Optimizedropoffs?":"Βελτιστοποιήστε τα dropoffs;",
  "OptimizedropoffsDesc":"Η ακολουθία των dropoffs θα αλλάξει σε βελτιστοποιημένη ακολουθία και μπορεί να αλλάξει την τιμή",
  "Optimize":"Βελτιστοποίηση της",

  
  "Bulkimport":"Μαζική εισαγωγή",
  "processing":"Επεξεργασία" ,
   "done":"Πεπερασμένος" ,
   "failed":"Απέτυχε",
   "Draft":"Προσχέδιο" ,
   "Uploading":"Μεταφόρτωση" ,
   "Draganddropyourfile":"Σύρετε και ρίξτε το αρχείο σας",
   "Onlycsvformatissupported":"Υποστηρίζεται μόνο μορφή .csv" ,
   "ORyoucan":"Ή μπορείτε",
   "Openfile":"Ανοιγμα αρχείου" ,
   "Noimportsyet":"Δεν υπάρχουν ακόμη εισαγωγές" ,
   "NoimportsyetDesc":"Μπορείτε να δείτε τη λίστα των εισαγωγών σας εδώ και να ελέγξετε την κατάστασή τους",
   "nosupportdialogeTitle":"Η μορφή αρχείου δεν υποστηρίζεται",
   "nosupportdialogeText":"Το αρχείο xxx δεν υποστηρίζεται.Μπορείτε να ανεβάσετε μόνο το αρχείο .csv συμπεριλαμβανομένων των πληροφοριών παραγγελίας" ,
   "Import":"Εισαγωγή",
   "previewTitle":"Προεπισκόπηση και επιλέξτε επιλογές",
   "previewDesc":"Αυτό είναι το πρώτο στοιχείο των αρχείων σας για να ελέγξετε τα στοιχεία σας είναι στη σωστή δομή" ,
   "Upload":"Μεταφορτώσω", 
   "Remove":"Αφαιρώ" ,
   "ServiceandOrdertype":"Υπηρεσία και τύπος παραγγελίας" ,
   "Fileisproccessing":"Το αρχείο επεξεργάζεται ...",
   "proccessingDesc":"Μπορείτε να ανανεώσετε για να δείτε αν το αποτέλεσμα είναι έτοιμο",
   "serverError":"Σφάλμα Διακομιστή",
   "isRequired":"Απαιτείται xxx" ,
   "Result":"Αποτέλεσμα",
   "ResultText":"Μπορείτε να δείτε τα προβλήματα εδώ και να κατεβάσετε αυτές τις σειρές για να διορθώσετε ξεχωριστά",
   "ErrorDetails":"Παραγγελίες με σφάλμα",
   "ErrorDetailsText":"Οι παραγγελίες με σφάλματα παρατίθενται στο αρχείο που μπορείτε να κατεβάσετε παρακάτω.Περιέχει λεπτομέρειες σφάλματος για κάθε σειρά.",
   "DownloadErroredRows":"Κατεβάστε τις σειρές λανθασμένων" ,
   "importedsuccessfully":"Οι παραγγελίες XXX εισάγονται με επιτυχία" ,
   "ordershaserrors":"Οι παραγγελίες XXX έχουν σφάλματα",
   "refresh":"Φρεσκάρω",
   "pleaseSellectParsel":"Επιλέξτε υπηρεσία",
   "Ondemand":"Κατα παραγγελια",
   "PickupandDelivery":"Παραλαβή και παράδοση",
   "PickupDelivery":"Παραλαβή και παράδοση",
   "Delivery":"Διανομή",
   "Pickup":"Μαζεύω",
   "tab_Delivery":"Διανομή",
   "tab_Ondemand":"Κατα παραγγελια",
   "tab_Pickup":"Μαζεύω",
   "tab_PickupDelivery":"P&D",
   "Downloadsamplecsv":"Κατεβάστε το δείγμα CSV" ,
   "driverPhoto":"Driver Photo",
   "yourPhoto":"Your Photo",
   "UploadPhoto":"Upload Photo",
   "Photos":"Photos",
   
   
   
   "TrackLink":"Track Link",
   "ShipmentLabel":"Shipment Label",
   "Draft":"Draft",
   "Receipt":"Receipt",
   "Confirm":"Confirm",
   "Cancel":"Cancel",
   "BulkEditStatusSummary":"Bulk Edit Status Summary",
   "Successful":"Successful",
   "Failed":"Failed",
   "OrderID":"Order ID",
   "Details":"Details",
   "Result":"Result",
   "New":"New",
   "Import":"Import",
   "Apply":"Apply",
   "Clear":"Clear",
   "Noresultfound":"No result found",
   "Typeyourkeyword":"Type your keyword",
   "Typeintheinputtostartsearch":"Type in the input to start search",
   "OnDemand":"On Demand",
   "P_Hub_D":"P_Hub_D",
   "AddressDetails":"Address Details",
   "Map":"Map",
   "Support":"Support",
   "BulkEditStatusxxx/yyy":"Bulk Edit Status xxx/yyy",
   "xxxSuccessfull,yyyFailed":"xxx Successfull, yyy Failed",
   "xxxfromyyy":"xxx from yyy",
   "xxxrows":"xxx rows",
   "xxxselected":"xxx selected",
   "xxxdropoffs":"+xxx dropoffs",
   "Status Updated at xxx":"Status Updated at xxx",
   'More':'More',
   'Summary':'Summary',
   'Prev':'Prev',
   'Next':'Next',
   'Done':'Done',
   "ClearAll":"Clear All",
   'Errorinloadingdata':'Error in loading data',
   'Therewasaproblemloadingdatapleasetryagainorcontactsupport':'There was a problem loading data. please try again or contact support',
   'Tryagain':'Try again',
   'ConfigTable' :'Config Table' ,
   'ActiveColumns':'Active Columns',
   'Createyourorder':'Create your order',
   'Yourorderswillbeshownhere':'Your orders will be shown here',
   'Pleasecheckyourfiltersorkeyword':'Please check your filters or keyword',
   "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap",
   'CustomerPhoto':'Customer Photo',
   "NoAttachments":"No Attachments",
   "AllAttachmentswillbeshownhere":"All Attachments will be shown here"
};
